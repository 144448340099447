import React, { Component } from "react";
// import { graphql } from "gatsby";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Layout from "ps-components/Layout/Layout.jsx";
import classNames from "classnames";
import PropTypes from "prop-types";
// import MUIDataTable from "mui-datatables";
import { Helmet } from "react-helmet";
import { createMuiTheme } from "@material-ui/core/styles";
import { withPrefix } from "gatsby";
import safebuyingstyle from "ps-assets/jss/safebuyingstyle"

let Url = process.env.BASE_URL;

class Safebuying extends Component {
  constructor(props) {
    super(props);
  }
  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true
      },
      overrides: {
        MUIDataTable: {
          paper: {
            boxShadow: `none`,
            paddingBottom: `20px`
          },
          responsiveScroll: { maxHeight: `auto !important` }
        },
      }
    });

  render = () => {
    const { classes} = this.props;
    return (
      <Layout small={true}>
        <Helmet key="helmetTags">
          <title>Safe buying | StarHealth.in</title>
        </Helmet>
        {process.env.ENABLE_HELMET == `true` && (
          <Helmet key="helmetTags">
            <meta name="title" content="Safe buying | StarHealth.in" />
            <meta name="twitter:title" content="Safe buying | StarHealth.in" />
            <meta property="og:title" content="Safe buying | StarHealth.in" />
            <meta property="og:url" content={Url + `safebuying`}/>
            <meta name="Safe buying | StarHealth.in" />
            <meta property="twitter:url" content={Url + `Safe buying`}/>
          </Helmet>
        )}
        <div
          className={classNames(classes.main, classes.mainRaised)}
          style={{ marginBottom: `100px` }}
        >
          <div className={classes.section}>
            <div className={ `${classes.container} ${classes.container1} `}>
              <GridContainer className={ `${classes.container} ${classes.container1} `}>
                <GridItem
                  md={9}
                  className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                >
                  <h1 className={`${classes.textCenter} ${classes.h2Title}`}>Important Advisory</h1>
                </GridItem>
                <GridItem
                  md={10}
                  className={`${classes.mrAuto}`}
                >
                  <div className={classes.textLeft}>
                    <h5 className={`${classes.textLeft} ${classes.subcontent} `}>Dear Policy Holders,</h5>
                    <p className={classes.subcontent} >Your safety and wellness is our top priority. Scamsters are devising every day new techniques to defraud our customers.  For creating awareness, we are highlighting the following cautions:</p>
                  </div>
                </GridItem>
                <GridItem
                  md={10}
                  className={`${classes.mrAuto}`}
                >
                  <div className={classes.textLeft}>
                    <div>
                      <h2 style={{float: `left`}} className={classes.h3textStyle}>SPOT: The Warning Signs  </h2>
                      <img className={classes.imagepadding} src={withPrefix(`/warning_signs.png`)} width={44} height={37} />
                    </div>
                    <br />
                    <div style={{listStyle: 'none'}} >
                      <ul className={classes.listalign} >
                      <li className={`${classes.infoArea} ${classes.listitems} `} >Are they asking you to act in haste for some additional benefits and gains?</li>
                      <li className={classes.infoArea}>Are they asking you to make urgent payments using UPI link or some garbled links?</li>
                      <li className={classes.infoArea}>Are they asking you to click on some link, which has unrecognized redirects for payments or personal information?</li>
                      </ul>
                    </div>
                  </div>
                </GridItem>
                <GridItem
                  md={10}
                  className={`${classes.mrAuto}`}
                >
                  <div className={classes.textLeft}>
                    <div>
                      <h2 style={{float: `left`}} className={classes.h3textStyle}>STOP: The doubtful activity </h2>
                      <img className={classes.imagepadding} src={withPrefix(`/doubtful_activity.png`)} width={42} height={37} />
                    </div>
                    <br />
                    <div>
                      <ul className={classes.listalign}>
                      <li className={`${classes.infoArea} ${classes.listitems} `} >Verify the authenticity of the source of  messages </li>
                      <li className={classes.infoArea}>Never share  OTP, CVV Number, or Credit / Debit Card Number and its PIN, or MPIN for UPI with anyone </li>
                      </ul>
                    </div>
                  </div>
                </GridItem>
                <GridItem
                  md={10}
                  className={`${classes.mrAuto}`}
                >
                  <div className={classes.textLeft}>
                    <div>
                      <h2 style={{float: `left`}} className={classes.h3textStyle}>REPORT: The incident   </h2>
                      <img style={{marginTop: '7px'}} className={classes.imagepadding} src={withPrefix(`/the_incident.png`)} width={37} height={25} />
                    </div>
                    <br />
                    <div>
                      <ul className={classes.listalign} >
                      <li className={`${classes.infoArea} ${classes.listitems} `}>The earlier you report to the company, chances are better to control fraud.</li>
                      </ul>
                      <br/>
                    </div>
                  </div>
                </GridItem>
                <GridItem
                  md={10}
                  className={`${classes.mrAuto}`}
                >
                  <div className={classes.textLeft}>
                    <p className={classes.subcontent} >Warm regards,</p>
                    <p className={classes.bottomline} >Team Star Health Insurance</p>
                    <p style={{marginTop: '-1%'}} className={classes.subcontent} >Website : 
                      <a href="https://www.starhealth.in/"> https://www.starhealth.in</a>
                    </p>
                    <p className={classes.bottomline}>Email : <a href="mailto:support@starhealth.in">support@starhealth.in</a>
                    </p>
                    <p style={{marginBottom: '4%'}} className={classes.bottomline} >Sales & Services - 044 4674 5800 </p>
                  </div>
                </GridItem>
                </GridContainer>
            </div>
          </div>
        </div>
      </Layout>
    );
  };
}

export default withStyles(safebuyingstyle)(Safebuying);

Safebuying.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};
