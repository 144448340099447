import {
  container,
  // mlAuto,
  mrAuto,
  title,
  description,
  main,
  mainRaised,
} from "assets/jss/material-kit-pro-react.jsx";
  
import sectionCards from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
// import Media from "components/Media/Media";
  
const safebuyingstyle = function (theme) {
  return {
    container,
    mrAuto,
    title,
    main,
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    ...modalStyle(theme),
    mainRaised,
    ...sectionCards,
    description,
    team: {
      padding: `50px 0px 30px 0px`,
    },
    section:{
      marginTop: `15%`,
      "@media (max-width:550px)": {
        marginTop: `30%`,
      },
      "@media (max-width:370px)": {
        marginTop: `30%`,
      },
      "@media (max-width:280px)": {
        marginTop: `40%`,
      },
    },
    container1: {
      paddingLeft: `20px`,
      "@media (max-width:550px)": {
        paddingLeft: `3px !important`,
        paddingRight: '3px !important'
      },
      "@media (max-width:370px)": {
        paddingLeft: `1px !important`,
        paddingRight: '1px !important'
      },
      "@media (max-width:280px)": {
        paddingLeft: `0px !important`,
        paddingRight: '0px !important'
      },
    },
    textCenter: {
      textAlign: `center`,
    },
    textleft: {
      textAlign: `left`,
    },
    subcontent:{
      fontFamily: `"Roboto Slab", "Times New Roman", serif`,
      fontWeight: `400`,
      fontSize:`14px`,
    },
    infoArea: {
      fontFamily: `"Roboto Slab", "Times New Roman", serif`,
      fontWeight: `400`,
      fontSize:`14px`,
    },
    h3textStyle:{
      color:` #3C4858`,
      fontWeight: `600`,
      fontFamily: `"Roboto Slab", "Times New Roman", serif`,
      textDecoration: `none`,
      fontSize:`1rem`,
      paddingLeft: `35px`,
      "@media (max-width:370px)": {
        paddingLeft: `15px !important`,
      },
      "@media (max-width:280px)": {
        paddingLeft: `0px !important`,
      },
    },
    h2Title:{
      color: `#3C4858`,
      fontSize:`1.75rem`,
      textDecoration: `none`,
      fontWeight: `700`,
      marginTop: `30px`,
      marginBottom: `25px`,
      minHeight: `32px`,
      fontFamily: `"Roboto Slab", "Times New Roman", serif`,
      [theme.breakpoints.down(`md`)]: {
        color: `#3C4858 !important`,
        textDecoration: `none !important`,
        fontWeight: `700 !important`,
        marginTop: `30px !important`,
        marginBottom: `25px !important`,
        minHeight: `32px !important`,
        fontFamily: `"Roboto Slab", "Times New Roman", serif !important`,
        fontSize:`28px !important`
      }
    },
    bottomline:{
      marginTop: '-1.75%',
      "@media (max-width:770px)": {
        marginTop: '-2.5%',
      },
      "@media (max-width:550px)": {
        marginTop: '-3.5%',
      },
      "@media (max-width:370px)": {
        marginTop: '-5%',
      },
      fontFamily: `"Roboto Slab", "Times New Roman", serif`,
      fontWeight: `400`,
      fontSize:`14px`,
    },
    listitems:{
      marginTop: '2.5% !important',
      "@media (max-width:1024px)": {
        marginTop: '4.5% !important',
      },
      "@media (max-width:770px)": {
        marginTop: '3.75% !important',
      },
      "@media (max-width:550px)": {
        marginTop: '8% !important',
      },
      "@media (max-width:370px)": {
        marginTop: '10% !important',
      },
      "@media (max-width:280px)": {
        marginTop: '9% !important',
      },
    },
    imagepadding:{
      float: `left`,
      paddingLeft: '1%',
      "@media (max-width:370px)": {
        paddingLeft: '2%'
      },
      "@media (max-width:280px)": {
        paddingRight: '1%'
      },
    },
    listalign:{
      paddingLeft: '52px',
      "@media (max-width:1024px)": {
        paddingLeft: '52px'
      },
      "@media (max-width:550px)": {
        paddingLeft: '52px'
      },
      "@media (max-width:370px)": {
        paddingLeft: '35px'
      },
    },
  };
};
  
export default safebuyingstyle;
  